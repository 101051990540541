"use client";

import React from "react";
import { cn } from "ui/lib/utils";
type TColorProp = `#${string}` | `#${string}`[];
interface ShineBorderProps {
  borderRadius?: number;
  borderWidth?: number;
  duration?: number;
  color?: TColorProp;
  className?: string;
  children: React.ReactNode;
  flexChildren?: boolean;
}
export function ShineBorder({
  borderRadius = 8,
  borderWidth = 1,
  duration = 14,
  color = "#fff",
  className,
  children,
  flexChildren = false
}: ShineBorderProps) {
  return <div style={{
    "--border-radius": `${borderRadius}px`
  } as React.CSSProperties} className={cn("relative grid min-h-[60px] w-fit min-w-[300px] place-items-center rounded-[--border-radius] p-3", className)} data-sentry-component="ShineBorder" data-sentry-source-file="index.tsx">
      <div style={{
      "--border-width": `${borderWidth}px`,
      "--border-radius": `${borderRadius}px`,
      "--border-radius-child": `${borderRadius * 0.2}px`,
      "--shine-pulse-duration": `${duration}s`,
      "--mask-linear-gradient": `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
      "--background-radial-gradient": `radial-gradient(transparent,transparent, ${!(color instanceof Array) ? color : color.join(",")},transparent,transparent)`
    } as React.CSSProperties} className={`before:bg-shine-size before:absolute before:inset-[0] before:aspect-square before:h-full before:w-full before:rounded-[--border-radius] before:p-[--border-width] before:will-change-[background-position] before:content-[""] before:![-webkit-mask-composite:xor] before:![mask-composite:exclude] before:[background-image:var(--background-radial-gradient)] before:[background-size:300%_300%] before:[mask:var(--mask-linear-gradient)] motion-safe:before:animate-[shine-pulse_var(--shine-pulse-duration)_infinite_linear]`}></div>
      <div className={cn("z-[1] h-full w-full rounded-[--border-radius-child]", {
      flex: flexChildren
    })}>
        {children}
      </div>
    </div>;
}