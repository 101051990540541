import AnimatedShinyText from "ui/components/shiny-text";
import { promoCodesToCopyText } from "web/components/notice/home";
export const PromoCodeShimmerText = ({
  promoCode,
  variant
}) => {
  const promoCodeCopyText = promoCodesToCopyText[promoCode?.toLowerCase?.()];
  if (variant === "referral") {
    return <AnimatedShinyText className="max-w-sm mx-auto text-center" shimmerWidth={209}>
        <span className="text-foreground/70">
          <span style={{
          backgroundImage: "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
          backgroundClip: "text"
        }} className="font-medium block text-white/40 bg-clip-text">
            No credit card to start • Expires in 24 hours
          </span>
        </span>
      </AnimatedShinyText>;
  }
  if (!promoCodeCopyText) return null;
  return <AnimatedShinyText className="max-w-sm mx-auto text-center" shimmerWidth={209} data-sentry-element="AnimatedShinyText" data-sentry-component="PromoCodeShimmerText" data-sentry-source-file="shimmer-text.tsx">
      <span className="text-foreground/70">
        <>
          Tap{" "}
          <span className="text-foreground animate-bounce animate-scale inline-block">
            {" "}
            👆
          </span>{" "}
          to automatically apply your{" "}
        </>
        <span style={{
        backgroundImage: "linear-gradient(128.99deg, #6AD3F4 -52.29%, #6AF478 18.9%, #D2FF51 108.52%)",
        backgroundClip: "text"
      }} className="font-medium block text-white/40 bg-clip-text">
          {promoCodeCopyText}
        </span>
      </span>
    </AnimatedShinyText>;
};