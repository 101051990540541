// VideoPlayer.tsx
"use client";

import Hls from "hls.js";
import React, { forwardRef, RefObject, useEffect, useRef, useState } from "react";

// -----------------------------
// Utility Function
// -----------------------------

/**
 * Determines if the provided URL is an HLS stream.
 * Typically, HLS streams have a `.m3u8` extension.
 *
 * @param url - The video URL to check.
 * @returns `true` if the URL is an HLS stream, otherwise `false`.
 */
const checkIsHlsMedia = (url: string): boolean => {
  return /\.m3u8$/i.test(url);
};

// -----------------------------
// Custom Hook: useHlsMedia
// -----------------------------

interface UseHlsMediaProps {
  ref: RefObject<HTMLVideoElement>;
  url: string;
  autoPlay: boolean;
}
interface UseHlsMediaReturn {
  error: boolean;
  isSupported: boolean;
  isNativeSupported: boolean;
}

/**
 * Custom hook to manage HLS media playback.
 * It initializes Hls.js if supported and handles native HLS playback.
 *
 * @param props - Properties including video ref, URL, and autoplay flag.
 * @returns An object containing error state, support flags.
 */
const useHlsMedia = ({
  ref,
  url,
  autoPlay
}: UseHlsMediaProps): UseHlsMediaReturn => {
  const [error, setError] = useState<boolean>(false);
  const [isSupported, setIsSupported] = useState<boolean>(false);
  const [isNativeSupported, setIsNativeSupported] = useState<boolean>(false);
  useEffect(() => {
    const video = ref.current;
    if (!video) {
      setError(true);
      return;
    }

    // Check for native HLS support (e.g., Safari)
    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = url;
      video.addEventListener("loadedmetadata", () => {
        if (autoPlay) {
          video.play().catch(e => {
            console.warn("Autoplay failed:", e);
          });
        }
      });
      setIsNativeSupported(true);
      return;
    }

    // Check if Hls.js is supported
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          video.play().catch(e => {
            console.warn("Autoplay failed:", e);
          });
        }
      });
      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error("HLS.js error:", data);
        setError(true);
      });
      setIsSupported(true);

      // Cleanup on unmount
      return () => {
        hls.destroy();
      };
    } else {
      // Neither native nor Hls.js is supported
      setError(true);
    }
  }, [ref, url, autoPlay]);
  return {
    error,
    isSupported: Hls.isSupported(),
    isNativeSupported: ["maybe", "probably"].includes(ref.current?.canPlayType("application/vnd.apple.mpegurl")) || false
  };
};

// -----------------------------
// Component: HlsVideoPlayer
// -----------------------------

interface HlsVideoPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  url: string;
  fallbackUrl?: string;
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  className?: string;
  poster?: string;
}

/**
 * Component to handle HLS video playback using Hls.js.
 *
 * @param props - Properties including URL, fallback URL, autoplay, etc.
 * @param forwardedRef - Ref to access the video element.
 * @returns JSX Element rendering the HLS video player or an error message.
 */
const HlsVideoPlayer = forwardRef<HTMLVideoElement, HlsVideoPlayerProps>((props, forwardedRef) => {
  const {
    autoPlay = true,
    loop = true,
    controls = false,
    url,
    fallbackUrl,
    className,
    poster,
    ...rest
  } = props;
  const internalRef = useRef<HTMLVideoElement>(null);
  const playerRef = forwardedRef as React.RefObject<HTMLVideoElement> || internalRef;
  const {
    error,
    isSupported,
    isNativeSupported
  } = useHlsMedia({
    ref: playerRef,
    url,
    autoPlay
  });
  if (error) {
    return <div className="error-message">
          <p>Your browser does not support HLS streaming.</p>
          <p>Please update your browser or try a different one.</p>
        </div>;
  }
  if (isSupported || isNativeSupported) {
    return <video ref={playerRef} width="100%" height="auto" autoPlay={autoPlay} muted={autoPlay} // Ensure muted when autoplaying
    controls={controls} loop={loop} poster={poster} className={className} {...rest}>
          {!isNativeSupported && fallbackUrl && <source src={fallbackUrl} type="video/mp4" />}
          Your browser does not support the video tag.
        </video>;
  }

  // Fallback if HLS is not supported and no fallback URL is provided
  return <div className="error-message">
        <p>Your browser does not support this video.</p>
        <p>Please update your browser or try a different one.</p>
      </div>;
});
HlsVideoPlayer.displayName = "HlsVideoPlayer";

// -----------------------------
// Component: VideoPlayer
// -----------------------------

interface VideoPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  url: string;
  fallbackUrl?: string;
  className?: string;
  type?: string;
}

/**
 * Main VideoPlayer component that decides to render either
 * an HLS video player or a standard HTML5 video player based on the URL and support.
 *
 * @param props - Properties including URL, fallback URL, autoplay, etc.
 * @param forwardedRef - Ref to access the video element.
 * @returns JSX Element rendering the appropriate video player.
 */
export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>((props, forwardedRef) => {
  const {
    autoPlay = true,
    loop = true,
    controls = false,
    url,
    className,
    type,
    fallbackUrl,
    poster,
    ...rest
  } = props;
  const isHls = checkIsHlsMedia(url);
  const hlsSupported = Hls.isSupported();
  if (isHls && hlsSupported) {
    // Render the HlsVideoPlayer if URL is HLS and HLS is supported
    return <HlsVideoPlayer ref={forwardedRef} url={url} fallbackUrl={fallbackUrl} autoPlay={autoPlay} loop={loop} controls={controls} className={className} poster={poster} {...rest} />;
  }

  // Render standard video player if not HLS or HLS is not supported
  return <video ref={forwardedRef} loop={loop} width="100%" height="auto" autoPlay={autoPlay} muted={autoPlay} // Ensure muted when autoplaying
  controls={controls} className={className} poster={poster} src={url || fallbackUrl} {...rest}>
        Your browser does not support the video tag.
      </video>;
});
VideoPlayer.displayName = "VideoPlayer";