import { SpeakerLoudIcon, SpeakerOffIcon } from "@radix-ui/react-icons";
import { motion } from "motion/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useInView from "react-cool-inview";
import {
  SnapItem,
  SnapList,
  useScroll,
  useVisibleElements,
} from "react-snaplist-carousel";
import { useVideo } from "react-use";
import BufferingSpinner from "ui/components/icons/BufferingSpinner";
import PlayIcon from "ui/components/icons/Play";
import { event } from "ui/lib/gtag";
import { useIsMounted } from "ui/lib/hooks";
import { useIsMobile } from "ui/lib/use-breakpoint";
import { cn } from "ui/lib/utils";
import { VideoPlayer } from "web/components/workout/player/video-player";

const Michael = {
  file: "Michael",
  name: "Michael L.",
  url: "https://stream.mux.com/MpDbGRCtFJNqcg73oUSC8102xLyMka8l5JQ01ZYrsgehk.m3u8",
  testimony:
    "My coach in my ear pushing me to be better, not just physically, but mentally day in day out.",
};
const Michelle = {
  file: "Michelle",
  name: "Michelle H.",
  url: "https://stream.mux.com/DcZlnZ3AzBTlUgN8VymbFCk2FW3KbWEOwp8TFe4MH3U.m3u8",
  testimony:
    "I needed structure in my life, I need someone to tell me what to do.",
};
const Ross = {
  file: "Ross",
  name: "Ross B.",
  url: "https://stream.mux.com/W02F01MLAP8Cjmf1tiBIWyBhswNWx01k6E8TPHlzwxOqZg.m3u8",
  testimony:
    "It helps me be consistent. I have a coach in my ear, telling me what to do.",
};
const Liz = {
  file: "Liz",
  name: "Liz W.",
  url: "https://stream.mux.com/nT02wyveTXA02Gs8zZtvj1DOIlR029gLHRy802196UEjOjM.m3u8",
  testimony:
    "Do the workout like it is. It’s there for a reason. It’s programed.",
};
const CC = {
  file: "CC",
  name: "CC A.",
  url: "https://stream.mux.com/zCGztVW5gjSj1BnR00jkwa8ebew02AYns3WGPlAY3mhFk.m3u8",
  testimony:
    "Honestly from the first workout I was like this is it. This is addictive, this is fun, this is very beneficial.",
};
const Kelly = {
  file: "Kelly",
  name: "Kelly C.",
  url: "https://stream.mux.com/nG7qWqwEr6H5JV3018Z2Q7LJt02MomASR00SmGBjBSHByI.m3u8",
  testimony: "Gave me the confidence to go to the gym, I know what to do now",
};

export const tesimonialDataDict = { Michael, Michelle, Ross, Kelly, CC, Liz };
const desktopTesimonialData = [Michael, Michelle, Ross, Kelly, CC, Liz];
const mobileTesimonialData = [Michelle, Michael, Kelly, Ross, Liz, CC];

const PlayButton = ({ visible }) => (
  <div
    className={cn(
      "center p-6 bg-white bg-opacity-50 border-4 border-white rounded-full",
      {
        "opacity-50": !visible,
      }
    )}
  >
    <PlayIcon className="w-10 h-10 translate-x-1 lg:w-12 lg:h-12 tranform" />
  </div>
);

const ContentOverlay = ({
  visible,
  content,
  onClick,
  videoState,
  buffering,
  duration,
  initiallyMuted,
  muted,
}) => (
  <div
    onClick={onClick}
    className={cn(
      "absolute inset-0 flex flex-col items-center justify-between cursor-pointer",
      {
        "bg-background/0": visible,
        "bg-background/40": !visible,
      }
    )}
  >
    <div className="h-32" />
    {initiallyMuted && muted ? (
      <SpeakerOffIcon className="absolute w-6 h-6 text-foreground left-4 top-4" />
    ) : initiallyMuted && !muted ? (
      <SpeakerLoudIcon className="absolute w-6 h-6 text-foreground left-4 top-4" />
    ) : null}
    {buffering ? (
      <BufferingSpinner className="w-10 h-10 text-foreground" />
    ) : videoState.paused ? (
      <PlayButton visible={visible} />
    ) : (
      <div />
    )}
    <div className="relative z-10 flex-col max-w-md p-8 mx-auto space-y-2 text-center center">
      <h4 className="text-xl text-foreground lg:text-2xl heading">
        {content.name}
      </h4>
      <p className="italic text-foreground lg:text-lg opacity-80">
        {content.testimony}
      </p>
    </div>
    <div
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.78) 45.41%, rgba(0, 0, 0, 0) 91.64%)`,
      }}
      className="absolute bottom-0 w-full h-48 transform"
    />
    {/* video progress bar using tailwindcss */}
    {duration || videoState.duration ? (
      <div className="absolute inset-x-0 bottom-0 w-auto h-1 transform">
        <div className="absolute w-full h-full bg-white rounded opacity-10" />
        <motion.div
          className="absolute h-full bg-white rounded opacity-75"
          style={{
            width: `${
              (100 * videoState.time) / (duration || videoState.duration)
            }%`,
          }}
        />
      </div>
    ) : null}
  </div>
);

export const VideoTestimonial = React.memo(
  ({
    content,
    onClick,
    visible,
    handleVideoEnded,
    shouldAutoPlay,
    autoPlay = false,
    muted: initiallyMuted = false,
    videoProps,
    showPoster = false,
  }) => {
    const isMounted = useIsMounted();
    const [muted, setMuted] = useState(initiallyMuted);
    const [duration, setDuration] = useState(0);

    const [video, videoState, videoControls, videoRef] = useVideo(
      <VideoPlayer
        className="w-full h-full max-w-full mx-auto shadow-xl rounded-xl"
        url={
          !!content.url
            ? content.url
            : `https://media.teams.joinladder.com/website/videos/testimonials/${content.file}.mp4`
        }
        fallbackUrl={
          !!content.url
            ? `https://media.teams.joinladder.com/website/videos/testimonials/${content.file}.mp4`
            : null
        }
        poster={
          showPoster
            ? `https://ladderteams.imgix.net/website/videos/testimonials/posters/${content.file}.png`
            : null
        }
        autoPlay={autoPlay}
        muted={muted}
        loop={false}
        controls={false}
        playsInline
        {...(videoProps || {})}
      />
    );

    useEffect(() => {
      if (shouldAutoPlay && visible) {
        videoControls.play();
      }
    }, [visible, shouldAutoPlay, videoControls]);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.addEventListener("loadedmetadata", () => {
          setDuration(videoRef.current?.duration || 0);
        });

        videoRef.current.onended = handleVideoEnded;
      }
    }, []);

    const buffering = useMemo(() => {
      if (videoState.paused) return false;

      const buffered = videoState.buffered;
      if (!buffered || buffered.length === 0) return true;

      const currentTime = videoState.time;

      // Iterate through each buffered range
      for (let i = 0; i < buffered.length; i++) {
        const range = buffered[i];
        if (currentTime >= range.start && currentTime < range.end) {
          return false; // Current time is within a buffered range
        }
      }

      return true; // Current time is not within any buffered range
    }, [videoState.paused, videoState.buffered, videoState.time]);

    const toggle = () => {
      onClick();
      event({
        action: "Customer_Testimonial_Clicked",
        category: "Customer_Testimonial",
        label: content.name,
        value: videoState.time,
      });
      if (videoState.paused) {
        setMuted(false);
        return videoControls.play();
      }
      if (initiallyMuted) {
        return setMuted((muted) => !muted);
      }

      videoControls.pause();
    };

    useEffect(() => {
      if (!visible) {
        videoControls.pause();
      }
    }, [visible]);

    const { observe } = useInView({
      onLeave: () => {
        videoControls.pause();
      },
    });

    if (!isMounted) return null;

    return (
      <>
        <div
          ref={observe}
          onClick={toggle}
          className={cn(
            "overflow-hidden rounded-xl min-w-fit snap-center w-full relative"
          )}
        >
          <motion.div
            className="overflow-hidden cursor-pointer rounded-xl"
            initial={visible ? "visible" : "shrunken"}
            animate={visible ? "visible" : "shrunken"}
            variants={{
              shrunken: { scale: 0.95 },
              visible: { scale: 1 },
            }}
          >
            {video}
            <ContentOverlay
              duration={duration}
              videoState={videoState}
              visible={visible}
              content={content}
              buffering={buffering}
              initiallyMuted={initiallyMuted}
              muted={muted}
            />
          </motion.div>
        </div>
      </>
    );
  }
);

export const VideoTestimonialsContainer = () => {
  const snapList = useRef(null);
  const [indexToAutoPlay, setIndexToAutoPlay] = useState();

  const visible = useVisibleElements(
    { debounce: 10, ref: snapList },
    (elements) => {
      switch (elements.length) {
        case 1:
          return elements[0];
        case 2:
        case 3:
          return elements[1];
        default:
          return elements[0];
      }
    }
  );
  const goToSnapItem = useScroll({ ref: snapList });
  const isMobile = useIsMobile();

  const { observe: observeContainer, inView: containerInView } = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  const { observe } = useInView({
    threshold: 0.5,
    onEnter: ({ unobserve }) => {
      if (!isMobile) {
        goToSnapItem(1, { animationEnabled: true });
        unobserve();
      }
    },
  });

  const itemArray = isMobile ? mobileTesimonialData : desktopTesimonialData;

  const handleVideoEnded = (index) => () => {
    let nextIndex;
    if (index === itemArray.length - 1) {
      nextIndex = 0;
    } else {
      nextIndex = index + 1;
    }

    setIndexToAutoPlay(nextIndex);
    goToSnapItem(nextIndex, { animationEnabled: true });

    setTimeout(() => {
      setIndexToAutoPlay();
    }, 1000);
  };

  const getMargin = (i, n) => {
    if (i === n) return { left: "12px", right: "40vw" };
    if (i === 0) return { left: "40vw", right: "12px" };
    return { left: "12px", right: "12px" };
  };

  return (
    <div ref={observeContainer} className="pb-12 space-y-12">
      <div className="px-2 space-y-8 lg:px-4">
        <div className={"relative max-w-full mx-auto lg:w-44 w-36"}>
          <img
            src="/assets/award-thing.svg"
            className="block w-full mx-auto"
            alt="womans health award"
          />
          <div
            style={{
              background: `linear-gradient(180deg, rgba(14, 14, 14, 0) 7.01%, #0E0E0E 69.3%)`,
            }}
            className="absolute bottom-0 w-full h-12 lg:h-16"
          />
        </div>
        <h3 className="text-3xl text-center text-foreground uppercase lg:text-5xl heading">
          WHAT PEOPLE LIKE YOU ARE SAYING
        </h3>
      </div>

      <div className="max-w-full mx-auto" ref={observe}>
        <SnapList
          direction="horizontal"
          className="overscroll-x-contain"
          ref={snapList}
        >
          {itemArray.map((content, index) => (
            <SnapItem
              key={index}
              margin={getMargin(index, itemArray.length - 1)}
              snapAlign="center"
              className="w-11/12 md:w-1/2 lg:w-1/3"
            >
              <VideoTestimonial
                onClick={() => goToSnapItem(index, { animationEnabled: true })}
                handleVideoEnded={handleVideoEnded(index)}
                shouldAutoPlay={indexToAutoPlay === index}
                visible={visible === index}
                content={content}
                showPoster={containerInView}
              />
            </SnapItem>
          ))}
        </SnapList>
      </div>

      <div className="container mx-auto space-x-2 center">
        {itemArray.map((_, index) => {
          const isActive = visible === index;
          return (
            <div
              key={index}
              onClick={() => goToSnapItem(index)}
              style={{ background: isActive ? "#E1E1E1" : "#545454" }}
              className={cn(
                "z-10 w-3 lg:w-4 h-3 lg:h-4 pointer text-center list-none rounded-full"
              )}
            />
          );
        })}
      </div>
    </div>
  );
};
